
function createCanonicalLink() {
  const linkNew = document.createElement("link");
  linkNew.setAttribute("rel", "canonical");
  linkNew.setAttribute("href", process.env.CANONICAL_URL);
  return linkNew;
}

function appendCanonicalLink() {
  const linkElement = createCanonicalLink();
  document.head.appendChild(linkElement);
}

// Append the canonical link on initialization
appendCanonicalLink();

// Expose the function to window for potential future use
window.createCanonicalLink = createCanonicalLink;
